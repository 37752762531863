/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyPlacesRequest } from '../models/BuyPlacesRequest';
import type { LineSpeedBoxStatisticsItem } from '../models/LineSpeedBoxStatisticsItem';
import type { PreOrderDeskListItem } from '../models/PreOrderDeskListItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SpeedBoxMarketingService {
  /**
   * Gets the list of desks in the SpeedBox marketing for pre-order
   * @returns any Returns the list of desks in the SpeedBox marketing for pre-order
   * @throws ApiError
   */
  public static getSpeedBoxPreOrderDeskList(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<PreOrderDeskListItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/pre-order/desk/list',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Buys a buy pre-order in the SpeedBox marketing
   * @param deskId SpeedBox Desk ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static buySpeedBoxPreOrder(deskId: number, requestBody: BuyPlacesRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/marketing/speedbox/pre-order/buy/{deskId}',
      path: {
        deskId: deskId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets pre-order desk statistics in SpeedBox marketing
   * @param offset Offset
   * @param limit Limit
   * @param line line id
   * @param username Username query
   * @param isBuy Filter for users who buy SpeedBox
   * @returns any Returns desk statistics in SpeedBox marketing
   * @throws ApiError
   */
  public static getSpeedBoxPreOrderStatistics(
    offset?: number,
    limit: number = 12,
    line: number = 1,
    username?: string,
    isBuy?: boolean
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<LineSpeedBoxStatisticsItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/marketing/speedbox/pre-order/statistics',
      query: {
        offset: offset,
        limit: limit,
        line: line,
        username: username,
        isBuy: isBuy,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }
}
