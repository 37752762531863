import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  ApiError,
  DeskItemPlaces6,
  DeskListItem4,
  MarketingStatistics,
  MegaBoxMarketingService,
  PreOrderDeskListItem,
} from 'api/client';
import RootStore from 'store';
import { InstallPlaceModalConfigType, UserClonesModalConfigType } from 'types/modalConfigs';
import {
  GroupedMegaBoxMarketingDesksType,
  GroupedMegaBoxMarketingPrestartDesksType,
  MegaBoxLockedDeskModalConfigType,
  MegaBoxMarketingStatsFilterType,
  PurchaseMegaBoxPlacesModalConfigType,
} from 'types/megaBoxMarketing';
import { powerSum } from 'utils/math';

export default class MegaBoxMarketingStore {
  rootStore: RootStore;

  purchasePlacesModalConfig: PurchaseMegaBoxPlacesModalConfigType | null;

  lockedDeskModalConfig: MegaBoxLockedDeskModalConfigType | null;

  installPlaceModalConfig: InstallPlaceModalConfigType | null;

  userClonesModalConfig: UserClonesModalConfigType | null;

  desks: DeskListItem4[] | null;

  prestartDesks: PreOrderDeskListItem[] | null;

  desk: DeskItemPlaces6 | null;

  depth: number;

  deskClonesTotal: number;

  isStatsFilterModalOpen: boolean;

  stats: {
    items: MarketingStatistics[];
    total: number;
    perPage: number;
    page: number;
    filter: MegaBoxMarketingStatsFilterType;
  };

  loading: {
    getDesk: boolean;
    getDesks: boolean;
    getPrestartDesks: boolean;
    getStats: boolean;
  };

  errors: {
    getDesk: ApiError | null;
    getDesks: ApiError | null;
    getPrestartDesks: ApiError | null;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'megaBoxMarketingStore' });
    this.rootStore = rootStore;
    this.purchasePlacesModalConfig = null;
    this.installPlaceModalConfig = null;
    this.userClonesModalConfig = null;
    this.isStatsFilterModalOpen = false;
    this.lockedDeskModalConfig = null;

    this.desks = null;
    this.prestartDesks = null;
    this.desk = null;
    this.depth = 1;
    this.deskClonesTotal = 0;
    this.stats = {
      items: [],
      total: 0,
      perPage: 12,
      page: 0,
      filter: {
        deskIds: [],
        lines: [],
        clones: false,
        placesLeft: [],
        username: '',
      },
    };

    this.loading = {
      getDesk: false,
      getDesks: false,
      getPrestartDesks: false,
      getStats: false,
    };
    this.errors = {
      getDesk: null,
      getDesks: null,
      getPrestartDesks: null,
    };
  }

  get desksGroupedByBlocks() {
    return this.desks ? (groupBy(this.desks, (desk) => desk.blockName) as GroupedMegaBoxMarketingDesksType) : null;
  }

  get prestartDesksGroupedByBlocks() {
    return this.prestartDesks
      ? (groupBy(this.prestartDesks, (desk) => desk.blockName) as GroupedMegaBoxMarketingPrestartDesksType)
      : null;
  }

  get isDeskNotFound() {
    return this.errors.getDesk?.status === 404;
  }

  get isDeskPacked() {
    return (this.desk?.current.isTriadic ? [0, 1, 2, 3] : [0, 1, 2]).every((index) => !!this.desk?.items[index]);
  }

  get deskLength() {
    if (!this.desk?.current) return 0;
    return this.desk.items;
  }

  normalizeTriadricNodes(desk: DeskItemPlaces6): DeskItemPlaces6 {
    if (desk.current.isTriadic) {
      return desk;
    } else {
      return {
        ...desk,
        items: {
          ...desk.items,
          '2': desk.items[3],
          '3': null,
        },
      };
    }
  }

  *getDesks() {
    try {
      this.loading.getDesks = true;
      const { data } = yield MegaBoxMarketingService.getMegaBoxDeskList();
      this.desks = data.items;
    } catch (error) {
      this.errors.getDesks = error as ApiError;
      console.log('[MegaBoxMarketingStore] getDesks error:', error);
    } finally {
      this.loading.getDesks = false;
    }
  }

  *getDesk(id: number) {
    try {
      this.loading.getDesk = true;
      const { data: deskData } = yield MegaBoxMarketingService.showMegaBoxDesk(id);
      const { data: clonesData } = yield MegaBoxMarketingService.myInstalledClonesInMegaBox(id);
      const nodes = (deskData as DeskItemPlaces6).current.isTriadic ? 3 : 2;
      const normalizedDesk = this.normalizeTriadricNodes(deskData);
      if (this.depth > 1) {
        // index of the last element in array tree
        const lastIndex = powerSum(nodes, this.depth) - 1;
        let currentIndex = 1;
        while (Object.values(normalizedDesk.items).length < lastIndex + nodes) {
          const rootPlaceId = normalizedDesk.items[currentIndex]?.id;
          const { data: rootDesk } = rootPlaceId
            ? yield MegaBoxMarketingService.showMegaBoxDeskFromPlace(rootPlaceId)
            : {
                data: {
                  current: { isTriadric: deskData.current.isTriadric },
                  items: {
                    ...Object.fromEntries(
                      Array.from({ length: nodes }).map((_, index) => [index + 1, null])
                    ) /* 1: null, 2: null, 3: null */,
                  },
                },
              };

          // eslint-disable-next-line no-loop-func, @typescript-eslint/no-loop-func
          Array.from({ length: nodes }).forEach((_, index) => {
            normalizedDesk.items[currentIndex * nodes + index + 1] =
              this.normalizeTriadricNodes(rootDesk).items[index + 1];
          });
          currentIndex += 1;
        }
      }

      this.desk = normalizedDesk;
      this.deskClonesTotal = clonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[MegaBoxMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  *getDeskByPlaceId(placeId: number) {
    this.loading.getDesk = true;
    try {
      const { data: deskData } = yield MegaBoxMarketingService.showMegaBoxDeskFromPlace(placeId);
      const { data: clonesData } = yield MegaBoxMarketingService.myInstalledClonesInMegaBox(deskData.current.id);
      const nodes = (deskData as DeskItemPlaces6).current.isTriadic ? 3 : 2;
      const normalizedDesk = this.normalizeTriadricNodes(deskData);
      if (this.depth > 1) {
        // index of the last element in array tree
        const lastIndex = powerSum(nodes, this.depth) - 1;
        let currentIndex = 1;
        while (Object.values(normalizedDesk.items).length < lastIndex + nodes) {
          const rootPlaceId = normalizedDesk.items[currentIndex]?.id;
          const { data: rootDesk } = rootPlaceId
            ? yield MegaBoxMarketingService.showMegaBoxDeskFromPlace(rootPlaceId)
            : {
                data: {
                  current: { isTriadric: deskData.current.isTriadric },
                  items: {
                    ...Object.fromEntries(
                      Array.from({ length: nodes }).map((_, index) => [index + 1, null])
                    ) /* 1: null, 2: null, 3: null */,
                  },
                },
              };

          // eslint-disable-next-line no-loop-func, @typescript-eslint/no-loop-func
          Array.from({ length: nodes }).forEach((_, index) => {
            normalizedDesk.items[currentIndex * nodes + index + 1] =
              this.normalizeTriadricNodes(rootDesk).items[index + 1];
          });
          currentIndex += 1;
        }
      }
      this.desk = normalizedDesk;
      this.deskClonesTotal = clonesData.total;
    } catch (error) {
      this.errors.getDesk = error as ApiError;
      console.log('[MegaBoxMarketingStore] getDesk error:', error);
    } finally {
      this.loading.getDesk = false;
    }
  }

  addTreeRow() {
    if (this.depth >= 5) return;
    this.depth = this.depth + 1;
  }

  removeTreeRow() {
    if (this.depth < 2) return;
    this.depth = this.depth - 1;
  }

  resetDesk() {
    this.errors.getDesk = null;
    this.desk = null;
  }

  *getStats() {
    try {
      this.loading.getStats = true;
      const { data } = yield MegaBoxMarketingService.showMegaBoxStatistics(
        this.stats.perPage * this.stats.page,
        this.stats.perPage,
        this.stats.filter.deskIds,
        this.stats.filter.lines as (4 | 1 | 2 | 3 | 5)[],
        this.stats.filter.clones,
        this.stats.filter.placesLeft as (1 | 2 | 3)[],
        this.stats.filter.username
      );

      this.stats = {
        ...this.stats,
        ...data,
      };
    } catch (error) {
      console.log('[MegaBoxMarketingStore] getStats error:', error);
    } finally {
      this.loading.getStats = false;
    }
  }

  updateStatsFilter(newFilter: MegaBoxMarketingStatsFilterType) {
    this.stats.filter = newFilter;
    this.stats.page = 0;
    this.getStats();
  }

  updateStatsPage(newPage: number) {
    this.stats.page = newPage;
    this.getStats();
  }

  openPurchasePlacesModal(config: PurchaseMegaBoxPlacesModalConfigType) {
    this.purchasePlacesModalConfig = config;
  }

  closePurchasePlacesModal() {
    this.purchasePlacesModalConfig = null;
  }

  openInstallPlaceModal(config: InstallPlaceModalConfigType) {
    this.installPlaceModalConfig = config;
  }

  closeInstallPlaceModal() {
    this.installPlaceModalConfig = null;
  }

  openUserClonesModal(config: UserClonesModalConfigType) {
    this.userClonesModalConfig = config;
  }

  closeUserClonesModal() {
    this.userClonesModalConfig = null;
  }

  openStatsFilterModal() {
    this.isStatsFilterModalOpen = true;
  }

  closeStatsFilterModal() {
    this.isStatsFilterModalOpen = false;
  }

  openLockedDeskModal(config: MegaBoxLockedDeskModalConfigType) {
    this.lockedDeskModalConfig = config;
  }

  closeLockedDeskModal() {
    this.lockedDeskModalConfig = null;
  }
}
