/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminChangeUserPasswordRequest } from '../models/AdminChangeUserPasswordRequest';
import type { AdminSystemNotificationItem } from '../models/AdminSystemNotificationItem';
import type { AdminUserItem } from '../models/AdminUserItem';
import type { ChangeCuratorRequest } from '../models/ChangeCuratorRequest';
import type { ChangeEmailRequest } from '../models/ChangeEmailRequest';
import type { ChangeFirstNameRequest } from '../models/ChangeFirstNameRequest';
import type { ChangeInstagramLoginRequest } from '../models/ChangeInstagramLoginRequest';
import type { ChangeLastNameRequest } from '../models/ChangeLastNameRequest';
import type { ChangePhoneRequest } from '../models/ChangePhoneRequest';
import type { ChangeTelegramLoginRequest } from '../models/ChangeTelegramLoginRequest';
import type { ChangeUsernameRequest } from '../models/ChangeUsernameRequest';
import type { ChangeVkLoginRequest } from '../models/ChangeVkLoginRequest';
import type { DetailTransactionViewModel } from '../models/DetailTransactionViewModel';
import type { DetailUserNotification } from '../models/DetailUserNotification';
import type { Enable2FaRequest } from '../models/Enable2FaRequest';
import type { FullReviewResponse } from '../models/FullReviewResponse';
import type { SystemNotificationMessageRequest } from '../models/SystemNotificationMessageRequest';
import type { UpdateBalanceRequest } from '../models/UpdateBalanceRequest';
import type { UpdateReviewRequest } from '../models/UpdateReviewRequest';
import type { UpdateTransactionRequest } from '../models/UpdateTransactionRequest';
import type { UserLogItem } from '../models/UserLogItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {
  /**
   * Gets a list of users
   * @param offset Offset
   * @param limit Limit
   * @param username Filter by username
   * @param fullName Filter by full name
   * @param email Filter by email
   * @param phone Filter by phone
   * @param id Filter by user ids
   * @param inviterId Filter by inviter user ids
   * @param isAdmin Filter by whether the user is an administrator
   * @param isActive Filter by whether the user is active or blocked
   * @param telegram Filter by telegram username
   * @param vk Filter by vkontakte username
   * @param instagram Filter by instagram username
   * @param createdAtStart Filter by registration date after
   * @param createdAtEnd Filter by registration date before
   * @param balanceGte Filter users with balance from passed value
   * @param balanceLte Filter users with balance up to the passed value
   * @param internalBalanceGte Filter users with internal balance from passed value
   * @param internalBalanceLte Filter users with internal balance up to the passed value
   * @param sumBalancesGte Filter users by the sum balances from a passed amount
   * @param sumBalancesLte Filter users by the sum balances to a passed amount
   * @param sort Sort results
   * @param order Order by results
   * @returns any Returns a list of users
   * @throws ApiError
   */
  public static getUserList(
    offset?: number,
    limit: number = 20,
    username?: string,
    fullName?: string,
    email?: string,
    phone?: string,
    id?: Array<string>,
    inviterId?: Array<string>,
    isAdmin?: 0 | 1 | null,
    isActive?: 0 | 1 | null,
    telegram?: string,
    vk?: string,
    instagram?: string,
    createdAtStart: string = '',
    createdAtEnd: string = '',
    balanceGte: number | null = null,
    balanceLte: number | null = null,
    internalBalanceGte: number | null = null,
    internalBalanceLte: number | null = null,
    sumBalancesGte: number | null = null,
    sumBalancesLte: number | null = null,
    sort?: 'created' | 'username' | 'balance' | 'internal_balance',
    order?: 'ASC' | 'DESC'
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<AdminUserItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users',
      query: {
        offset: offset,
        limit: limit,
        username: username,
        fullName: fullName,
        email: email,
        phone: phone,
        'id[]': id,
        'inviterId[]': inviterId,
        isAdmin: isAdmin,
        isActive: isActive,
        telegram: telegram,
        vk: vk,
        instagram: instagram,
        'createdAt[start]': createdAtStart,
        'createdAt[end]': createdAtEnd,
        'balance[gte]': balanceGte,
        'balance[lte]': balanceLte,
        'internalBalance[gte]': internalBalanceGte,
        'internalBalance[lte]': internalBalanceLte,
        'sumBalances[gte]': sumBalancesGte,
        'sumBalances[lte]': sumBalancesLte,
        sort: sort,
        order: order,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets a user by id.
   * @param userId User ID
   * @returns any Returns a user by username.
   * @throws ApiError
   */
  public static getOneUser(userId: string): CancelablePromise<{
    status: boolean;
    data: AdminUserItem;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users/{userId}',
      path: {
        userId: userId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Administrator changes user's first name of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserFirstName(userId: string, requestBody: ChangeFirstNameRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/first-name',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes email of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserEmail(userId: string, requestBody: ChangeEmailRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/email',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes user's last name of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserLastName(userId: string, requestBody: ChangeLastNameRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/last-name',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Changes the user's curator
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeCurator(userId: string, requestBody: ChangeCuratorRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/curator',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes username of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserUsername(userId: string, requestBody: ChangeUsernameRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/username',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes the mobile phone number of a specific user
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserPhone(userId: string, requestBody: ChangePhoneRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/phone',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Admin changes user financial password
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserFinancialPassword(
    userId: string,
    requestBody: AdminChangeUserPasswordRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/financial-password',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Admin changes user password
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserPassword(
    userId: string,
    requestBody: AdminChangeUserPasswordRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/password',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator changes the instagram login of a specific user
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserInstagramLogin(
    userId: string,
    requestBody: ChangeInstagramLoginRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/social/instagram',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Administrator changes the telegram login of a specific user
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserTelegramLogin(
    userId: string,
    requestBody: ChangeTelegramLoginRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/social/telegram',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Administrator changes the vk login of a specific user
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminChangeUserVkLogin(userId: string, requestBody: ChangeVkLoginRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/social/vk',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Administrator enables 2fa for login
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminEnable2FaForLogin(userId: string, requestBody: Enable2FaRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/2fa/login',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Administrator enables 2fa for purchase
   * @param userId User ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static adminEnable2FaForPurchase(userId: string, requestBody: Enable2FaRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/settings/2fa/purchase',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Blocks the user
   * @param userId User ID
   * @returns void
   * @throws ApiError
   */
  public static blockUser(userId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/users/{userId}/block',
      path: {
        userId: userId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Unblocks the user
   * @param userId User ID
   * @returns void
   * @throws ApiError
   */
  public static unblockUser(userId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/users/{userId}/unblock',
      path: {
        userId: userId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Assign role
   * @param userId User ID
   * @param role New Role
   * @returns void
   * @throws ApiError
   */
  public static assignRole(userId: string, role: 'admin' | 'support' | 'smm'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/{userId}/role/{role}',
      path: {
        userId: userId,
        role: role,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Unassign role
   * @param userId User ID
   * @param role Delete Role
   * @returns void
   * @throws ApiError
   */
  public static unassignRole(userId: string, role: 'admin' | 'support' | 'smm'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/users/{userId}/role/{role}',
      path: {
        userId: userId,
        role: role,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the authorization log of a specific user
   * @param userId User ID
   * @param offset Offset
   * @param limit Limit
   * @param createdAtStart Filter by date after
   * @param createdAtEnd Filter by date before
   * @param order Order by results
   * @returns any Returns the authorization log of a specific user
   * @throws ApiError
   */
  public static getUserAuthLogs(
    userId: string,
    offset?: number,
    limit: number = 20,
    createdAtStart: string = '',
    createdAtEnd: string = '',
    order: 'ASC' | 'DESC' = 'DESC'
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<UserLogItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users/{userId}/logs/auth',
      path: {
        userId: userId,
      },
      query: {
        offset: offset,
        limit: limit,
        'createdAt[start]': createdAtStart,
        'createdAt[end]': createdAtEnd,
        order: order,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets a list of system notifications for the admin.
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of system notifications for the admin.
   * @throws ApiError
   */
  public static getAdminSystemNotificationList(
    offset?: number,
    limit: number = 20
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<AdminSystemNotificationItem>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users/notification/system',
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Creates a system notification for all users.
   * @param requestBody Creates a system notification for all users.
   * @returns void
   * @throws ApiError
   */
  public static createSystemNotification(requestBody: SystemNotificationMessageRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/users/notification/system',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Updates the system notification.
   * @param notificationId
   * @param requestBody Updates the system notification.
   * @returns void
   * @throws ApiError
   */
  public static updateSystemNotification(
    notificationId: string,
    requestBody: SystemNotificationMessageRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/users/notification/system/{notificationId}',
      path: {
        notificationId: notificationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Deletes the system notification.
   * @param notificationId
   * @returns void
   * @throws ApiError
   */
  public static deleteSystemNotification(notificationId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/users/notification/system/{notificationId}',
      path: {
        notificationId: notificationId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of personal user notifications
   * @param offset Offset
   * @param limit Limit
   * @param fromUser From user ID
   * @param toUser To user ID
   * @returns any Returns a list of system notifications for the admin.
   * @throws ApiError
   */
  public static getAdminPersonalNotificationList(
    offset?: number,
    limit: number = 20,
    fromUser?: string,
    toUser?: string
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<DetailUserNotification>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users/notification/personal',
      query: {
        offset: offset,
        limit: limit,
        fromUser: fromUser,
        toUser: toUser,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Removes the user's personal notification.
   * @param notificationId
   * @returns void
   * @throws ApiError
   */
  public static deletePersonalUserNotification(notificationId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/users/notification/personal/{notificationId}',
      path: {
        notificationId: notificationId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of reviews for the admin
   * @param offset Offset
   * @param limit Limit
   * @param status Review Status
   * @param isPublishedOnMain Is it published on the main
   * @returns any Returns a list of reviews for the admin
   * @throws ApiError
   */
  public static getAdminReviewList(
    offset?: number,
    limit: number = 20,
    status?: 0 | 1 | 2,
    isPublishedOnMain?: boolean
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<FullReviewResponse>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/reviews',
      query: {
        offset: offset,
        limit: limit,
        status: status,
        isPublishedOnMain: isPublishedOnMain,
      },
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Changes the review status to accepted or rejected
   * @param reviewId Review ID
   * @param status New status
   * @returns void
   * @throws ApiError
   */
  public static changeReviewStatus(reviewId: string, status: 'approve' | 'decline'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/review/{reviewId}/status/{status}',
      path: {
        reviewId: reviewId,
        status: status,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Adds or removes review from the main page
   * @param reviewId Review ID
   * @param action Add or remove a review from the main
   * @returns void
   * @throws ApiError
   */
  public static publishedReviewOnMainPage(reviewId: string, action: 'add' | 'remove'): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/review/{reviewId}/main-page/{action}',
      path: {
        reviewId: reviewId,
        action: action,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Updates the review
   * @param reviewId Review ID
   * @param requestBody Updates the review
   * @returns void
   * @throws ApiError
   */
  public static updateReview(reviewId: string, requestBody: UpdateReviewRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/review/{reviewId}',
      path: {
        reviewId: reviewId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Deletes the review
   * @param reviewId Review ID
   * @returns void
   * @throws ApiError
   */
  public static deleteReview(reviewId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/review/{reviewId}',
      path: {
        reviewId: reviewId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates the user's balance
   * @param userId ID of the user whose balance is being updated
   * @param requestBody New balance values
   * @returns void
   * @throws ApiError
   */
  public static updateBalance(userId: string, requestBody: UpdateBalanceRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/accounting/{userId}/balance',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Approves a user transaction for withdrawal
   * After the transaction is approved, the transfer process starts. If an error occurs during the transfer, the transaction is marked as pending.
   * @param transactionId Transaction ID
   * @returns void
   * @throws ApiError
   */
  public static adminWithdrawTransactionApprove(transactionId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/accounting/withdraw/{transactionId}/approve',
      path: {
        transactionId: transactionId,
      },
      errors: {
        403: `Access Denied`,
        404: `Transaction not found.`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Rejects a user transaction for withdrawal
   * After the transaction is rejected, the holden money is automatically returned to the balance.
   * @param transactionId Transaction ID
   * @returns void
   * @throws ApiError
   */
  public static adminWithdrawTransactionReject(transactionId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/accounting/withdraw/{transactionId}/reject',
      path: {
        transactionId: transactionId,
      },
      errors: {
        403: `Access Denied`,
        404: `Transaction not found.`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets a detailed list of transactions for all users.
   * @param offset Offset
   * @param limit Limit
   * @param username Filter by username
   * @param status Transaction statuses (optional)
   * @param type Transaction types (optional)
   * @param direction Transaction direction (optional)
   * @param createdAtStart Filter by transaction date after
   * @param createdAtEnd Filter by transaction date before
   * @param sort Sort results
   * @param order Order by results
   * @returns any Returns a detailed list of transactions for all users.
   * @throws ApiError
   */
  public static getDetailUserTransactionList(
    offset?: number,
    limit: number = 20,
    username?: string,
    status?: Array<'pending' | 'completed' | 'processing' | 'rejected' | 'cancelled'>,
    type?: Array<
      | 'deposit'
      | 'withdraw'
      | 'transfer_from'
      | 'transfer_to'
      | 'purchase_return'
      | 'buy_desk_greenbox_1'
      | 'buy_desk_greenbox_2'
      | 'buy_desk_greenbox_3'
      | 'buy_desk_greenbox_4'
      | 'buy_desk_bluebox_1'
      | 'buy_desk_bluebox_2'
      | 'buy_desk_bluebox_3'
      | 'buy_desk_bluebox_4'
      | 'buy_desk_redbox_1'
      | 'buy_desk_redbox_2'
      | 'buy_desk_redbox_3'
      | 'buy_desk_redbox_4'
      | 'buy_clone_greenbox_1'
      | 'buy_clone_greenbox_2'
      | 'buy_clone_greenbox_3'
      | 'buy_clone_greenbox_4'
      | 'buy_clone_bluebox_1'
      | 'buy_clone_bluebox_2'
      | 'buy_clone_bluebox_3'
      | 'buy_clone_bluebox_4'
      | 'buy_clone_redbox_1'
      | 'buy_clone_redbox_2'
      | 'buy_clone_redbox_3'
      | 'buy_clone_redbox_4'
      | 'bonus_greenbox_1'
      | 'bonus_greenbox_2'
      | 'bonus_greenbox_3'
      | 'bonus_greenbox_4'
      | 'bonus_bluebox_1'
      | 'bonus_bluebox_2'
      | 'bonus_bluebox_3'
      | 'bonus_bluebox_4'
      | 'bonus_redbox_1'
      | 'bonus_redbox_2'
      | 'bonus_redbox_3'
      | 'bonus_redbox_4'
      | 'referral_bonus_greenbox_1'
      | 'referral_bonus_greenbox_2'
      | 'referral_bonus_greenbox_3'
      | 'referral_bonus_greenbox_4'
      | 'referral_bonus_bluebox_1'
      | 'referral_bonus_bluebox_2'
      | 'referral_bonus_bluebox_3'
      | 'referral_bonus_bluebox_4'
      | 'referral_bonus_redbox_1'
      | 'referral_bonus_redbox_2'
      | 'referral_bonus_redbox_3'
      | 'referral_bonus_redbox_4'
      | 'cumulative_bonus_greenbox_1'
      | 'cumulative_bonus_greenbox_2'
      | 'cumulative_bonus_greenbox_3'
      | 'cumulative_bonus_greenbox_4'
      | 'cumulative_bonus_bluebox_1'
      | 'cumulative_bonus_bluebox_2'
      | 'cumulative_bonus_bluebox_3'
      | 'cumulative_bonus_bluebox_4'
      | 'cumulative_bonus_redbox_1'
      | 'cumulative_bonus_redbox_2'
      | 'cumulative_bonus_redbox_3'
      | 'cumulative_bonus_redbox_4'
      | 'buy_magicbox_ticket'
      | 'refund_magicbox_ticket'
      | 'buy_pre_order_boombox_bronze_1'
      | 'buy_pre_order_boombox_bronze_2'
      | 'buy_pre_order_boombox_bronze_3'
      | 'buy_pre_order_boombox_bronze_4'
      | 'buy_pre_order_boombox_bronze_5'
      | 'buy_pre_order_boombox_bronze_6'
      | 'buy_pre_order_boombox_silver_1'
      | 'buy_pre_order_boombox_silver_2'
      | 'buy_pre_order_boombox_silver_3'
      | 'buy_pre_order_boombox_silver_4'
      | 'buy_pre_order_boombox_silver_5'
      | 'buy_pre_order_boombox_silver_6'
      | 'buy_pre_order_boombox_gold_1'
      | 'buy_pre_order_boombox_gold_2'
      | 'buy_pre_order_boombox_gold_3'
      | 'buy_pre_order_boombox_gold_4'
      | 'buy_pre_order_boombox_gold_5'
      | 'buy_pre_order_boombox_gold_6'
      | 'buy_desk_boombox_bronze_1'
      | 'buy_desk_boombox_bronze_2'
      | 'buy_desk_boombox_bronze_3'
      | 'buy_desk_boombox_bronze_4'
      | 'buy_desk_boombox_bronze_5'
      | 'buy_desk_boombox_bronze_6'
      | 'buy_desk_boombox_silver_1'
      | 'buy_desk_boombox_silver_2'
      | 'buy_desk_boombox_silver_3'
      | 'buy_desk_boombox_silver_4'
      | 'buy_desk_boombox_silver_5'
      | 'buy_desk_boombox_silver_6'
      | 'buy_desk_boombox_gold_1'
      | 'buy_desk_boombox_gold_2'
      | 'buy_desk_boombox_gold_3'
      | 'buy_desk_boombox_gold_4'
      | 'buy_desk_boombox_gold_5'
      | 'buy_desk_boombox_gold_6'
      | 'buy_clone_boombox_bronze_1'
      | 'buy_clone_boombox_bronze_2'
      | 'buy_clone_boombox_bronze_3'
      | 'buy_clone_boombox_bronze_4'
      | 'buy_clone_boombox_bronze_5'
      | 'buy_clone_boombox_bronze_6'
      | 'buy_clone_boombox_silver_1'
      | 'buy_clone_boombox_silver_2'
      | 'buy_clone_boombox_silver_3'
      | 'buy_clone_boombox_silver_4'
      | 'buy_clone_boombox_silver_5'
      | 'buy_clone_boombox_silver_6'
      | 'buy_clone_boombox_gold_1'
      | 'buy_clone_boombox_gold_2'
      | 'buy_clone_boombox_gold_3'
      | 'buy_clone_boombox_gold_4'
      | 'buy_clone_boombox_gold_5'
      | 'buy_clone_boombox_gold_6'
      | 'bonus_bronze_1'
      | 'bonus_bronze_2'
      | 'bonus_bronze_3'
      | 'bonus_bronze_4'
      | 'bonus_bronze_5'
      | 'bonus_bronze_6'
      | 'bonus_silver_1'
      | 'bonus_silver_2'
      | 'bonus_silver_3'
      | 'bonus_silver_4'
      | 'bonus_silver_5'
      | 'bonus_silver_6'
      | 'bonus_gold_1'
      | 'bonus_gold_2'
      | 'bonus_gold_3'
      | 'bonus_gold_4'
      | 'bonus_gold_5'
      | 'bonus_gold_6'
      | 'cumulative_bonus_bronze_1'
      | 'cumulative_bonus_bronze_2'
      | 'cumulative_bonus_bronze_3'
      | 'cumulative_bonus_bronze_4'
      | 'cumulative_bonus_bronze_5'
      | 'cumulative_bonus_bronze_6'
      | 'cumulative_bonus_silver_1'
      | 'cumulative_bonus_silver_2'
      | 'cumulative_bonus_silver_3'
      | 'cumulative_bonus_silver_4'
      | 'cumulative_bonus_silver_5'
      | 'cumulative_bonus_silver_6'
      | 'cumulative_bonus_gold_1'
      | 'cumulative_bonus_gold_2'
      | 'cumulative_bonus_gold_3'
      | 'cumulative_bonus_gold_4'
      | 'cumulative_bonus_gold_5'
      | 'cumulative_bonus_gold_6'
      | 'referral_bonus_bronze_1'
      | 'referral_bonus_bronze_2'
      | 'referral_bonus_bronze_3'
      | 'referral_bonus_bronze_4'
      | 'referral_bonus_bronze_5'
      | 'referral_bonus_bronze_6'
      | 'referral_bonus_silver_1'
      | 'referral_bonus_silver_2'
      | 'referral_bonus_silver_3'
      | 'referral_bonus_silver_4'
      | 'referral_bonus_silver_5'
      | 'referral_bonus_silver_6'
      | 'referral_bonus_gold_1'
      | 'referral_bonus_gold_2'
      | 'referral_bonus_gold_3'
      | 'referral_bonus_gold_4'
      | 'referral_bonus_gold_5'
      | 'referral_bonus_gold_6'
      | 'buy_pre_order_top_box_emerald_1'
      | 'buy_pre_order_top_box_emerald_2'
      | 'buy_pre_order_top_box_emerald_3'
      | 'buy_pre_order_top_box_ruby_1'
      | 'buy_pre_order_top_box_ruby_2'
      | 'buy_pre_order_top_box_ruby_3'
      | 'buy_pre_order_top_box_diamond_1'
      | 'buy_pre_order_top_box_diamond_2'
      | 'buy_pre_order_top_box_diamond_3'
      | 'buy_desk_top_box_emerald_1'
      | 'buy_desk_top_box_emerald_2'
      | 'buy_desk_top_box_emerald_3'
      | 'buy_desk_top_box_ruby_1'
      | 'buy_desk_top_box_ruby_2'
      | 'buy_desk_top_box_ruby_3'
      | 'buy_desk_top_box_diamond_1'
      | 'buy_desk_top_box_diamond_2'
      | 'buy_desk_top_box_diamond_3'
      | 'buy_clone_top_box_emerald_1'
      | 'buy_clone_top_box_emerald_2'
      | 'buy_clone_top_box_emerald_3'
      | 'buy_clone_top_box_ruby_1'
      | 'buy_clone_top_box_ruby_2'
      | 'buy_clone_top_box_ruby_3'
      | 'buy_clone_top_box_diamond_1'
      | 'buy_clone_top_box_diamond_2'
      | 'buy_clone_top_box_diamond_3'
      | 'bonus_top_box_emerald_1'
      | 'bonus_top_box_emerald_2'
      | 'bonus_top_box_emerald_3'
      | 'bonus_top_box_ruby_1'
      | 'bonus_top_box_ruby_2'
      | 'bonus_top_box_ruby_3'
      | 'bonus_top_box_diamond_1'
      | 'bonus_top_box_diamond_2'
      | 'bonus_top_box_diamond_3'
      | 'cumulative_bonus_top_box_emerald_1'
      | 'cumulative_bonus_top_box_emerald_2'
      | 'cumulative_bonus_top_box_emerald_3'
      | 'cumulative_bonus_top_box_ruby_1'
      | 'cumulative_bonus_top_box_ruby_2'
      | 'cumulative_bonus_top_box_ruby_3'
      | 'cumulative_bonus_top_box_diamond_1'
      | 'cumulative_bonus_top_box_diamond_2'
      | 'cumulative_bonus_top_box_diamond_3'
      | 'referral_bonus_top_box_emerald_line_1'
      | 'referral_bonus_top_box_emerald_line_2'
      | 'referral_bonus_top_box_emerald_line_3'
      | 'referral_bonus_top_box_emerald_line_4'
      | 'referral_bonus_top_box_emerald_line_5'
      | 'referral_bonus_top_box_ruby_line_1'
      | 'referral_bonus_top_box_ruby_line_2'
      | 'referral_bonus_top_box_ruby_line_3'
      | 'referral_bonus_top_box_ruby_line_4'
      | 'referral_bonus_top_box_ruby_line_5'
      | 'referral_bonus_top_box_diamond_line_1'
      | 'referral_bonus_top_box_diamond_line_2'
      | 'referral_bonus_top_box_diamond_line_3'
      | 'referral_bonus_top_box_diamond_line_4'
      | 'referral_bonus_top_box_diamond_line_5'
      | 'buy_pre_order_tictac_yellow_1'
      | 'buy_pre_order_tictac_yellow_2'
      | 'buy_pre_order_tictac_yellow_3'
      | 'buy_pre_order_tictac_yellow_4'
      | 'buy_pre_order_tictac_yellow_5'
      | 'buy_pre_order_tictac_yellow_6'
      | 'buy_pre_order_tictac_green_1'
      | 'buy_pre_order_tictac_green_2'
      | 'buy_pre_order_tictac_green_3'
      | 'buy_pre_order_tictac_green_4'
      | 'buy_pre_order_tictac_green_5'
      | 'buy_pre_order_tictac_green_6'
      | 'buy_pre_order_tictac_orange_1'
      | 'buy_pre_order_tictac_orange_2'
      | 'buy_pre_order_tictac_orange_3'
      | 'buy_pre_order_tictac_orange_4'
      | 'buy_pre_order_tictac_orange_5'
      | 'buy_pre_order_tictac_orange_6'
      | 'buy_desk_tictac_yellow_1'
      | 'buy_desk_tictac_yellow_2'
      | 'buy_desk_tictac_yellow_3'
      | 'buy_desk_tictac_yellow_4'
      | 'buy_desk_tictac_yellow_5'
      | 'buy_desk_tictac_yellow_6'
      | 'buy_clone_tictac_yellow_1'
      | 'buy_clone_tictac_yellow_2'
      | 'buy_clone_tictac_yellow_3'
      | 'buy_clone_tictac_yellow_4'
      | 'buy_clone_tictac_yellow_5'
      | 'buy_clone_tictac_yellow_6'
      | 'bonus_yellow_1'
      | 'bonus_yellow_2'
      | 'bonus_yellow_3'
      | 'bonus_yellow_4'
      | 'bonus_yellow_5'
      | 'bonus_yellow_6'
      | 'referral_bonus_yellow_1'
      | 'referral_bonus_yellow_2'
      | 'referral_bonus_yellow_3'
      | 'referral_bonus_yellow_4'
      | 'referral_bonus_yellow_5'
      | 'referral_bonus_yellow_6'
      | 'cumulative_bonus_yellow_1'
      | 'cumulative_bonus_yellow_2'
      | 'cumulative_bonus_yellow_3'
      | 'cumulative_bonus_yellow_4'
      | 'cumulative_bonus_yellow_5'
      | 'cumulative_bonus_yellow_6'
      | 'buy_desk_tictac_green_1'
      | 'buy_desk_tictac_green_2'
      | 'buy_desk_tictac_green_3'
      | 'buy_desk_tictac_green_4'
      | 'buy_desk_tictac_green_5'
      | 'buy_desk_tictac_green_6'
      | 'buy_clone_tictac_green_1'
      | 'buy_clone_tictac_green_2'
      | 'buy_clone_tictac_green_3'
      | 'buy_clone_tictac_green_4'
      | 'buy_clone_tictac_green_5'
      | 'buy_clone_tictac_green_6'
      | 'bonus_green_1'
      | 'bonus_green_2'
      | 'bonus_green_3'
      | 'bonus_green_4'
      | 'bonus_green_5'
      | 'bonus_green_6'
      | 'referral_bonus_green_1'
      | 'referral_bonus_green_2'
      | 'referral_bonus_green_3'
      | 'referral_bonus_green_4'
      | 'referral_bonus_green_5'
      | 'referral_bonus_green_6'
      | 'cumulative_bonus_green_1'
      | 'cumulative_bonus_green_2'
      | 'cumulative_bonus_green_3'
      | 'cumulative_bonus_green_4'
      | 'cumulative_bonus_green_5'
      | 'cumulative_bonus_green_6'
      | 'buy_desk_tictac_orange_1'
      | 'buy_desk_tictac_orange_2'
      | 'buy_desk_tictac_orange_3'
      | 'buy_desk_tictac_orange_4'
      | 'buy_desk_tictac_orange_5'
      | 'buy_desk_tictac_orange_6'
      | 'buy_clone_tictac_orange_1'
      | 'buy_clone_tictac_orange_2'
      | 'buy_clone_tictac_orange_3'
      | 'buy_clone_tictac_orange_4'
      | 'buy_clone_tictac_orange_5'
      | 'buy_clone_tictac_orange_6'
      | 'bonus_orange_1'
      | 'bonus_orange_2'
      | 'bonus_orange_3'
      | 'bonus_orange_4'
      | 'bonus_orange_5'
      | 'bonus_orange_6'
      | 'referral_bonus_orange_1'
      | 'referral_bonus_orange_2'
      | 'referral_bonus_orange_3'
      | 'referral_bonus_orange_4'
      | 'referral_bonus_orange_5'
      | 'referral_bonus_orange_6'
      | 'cumulative_bonus_orange_1'
      | 'cumulative_bonus_orange_2'
      | 'cumulative_bonus_orange_3'
      | 'cumulative_bonus_orange_4'
      | 'cumulative_bonus_orange_5'
      | 'cumulative_bonus_orange_6'
      | 'buy_pre_order_klondaik_red_gold_1'
      | 'buy_pre_order_klondaik_red_gold_2'
      | 'buy_pre_order_klondaik_red_gold_3'
      | 'buy_pre_order_klondaik_red_gold_4'
      | 'buy_pre_order_klondaik_red_gold_5'
      | 'buy_pre_order_klondaik_red_gold_6'
      | 'buy_pre_order_klondaik_yellow_gold_1'
      | 'buy_pre_order_klondaik_yellow_gold_2'
      | 'buy_pre_order_klondaik_yellow_gold_3'
      | 'buy_pre_order_klondaik_yellow_gold_4'
      | 'buy_pre_order_klondaik_yellow_gold_5'
      | 'buy_pre_order_klondaik_yellow_gold_6'
      | 'buy_pre_order_klondaik_white_gold_1'
      | 'buy_pre_order_klondaik_white_gold_2'
      | 'buy_pre_order_klondaik_white_gold_3'
      | 'buy_pre_order_klondaik_white_gold_4'
      | 'buy_pre_order_klondaik_white_gold_5'
      | 'buy_pre_order_klondaik_white_gold_6'
      | 'buy_desk_klondaik_red_gold_1'
      | 'buy_desk_klondaik_red_gold_2'
      | 'buy_desk_klondaik_red_gold_3'
      | 'buy_desk_klondaik_red_gold_4'
      | 'buy_desk_klondaik_red_gold_5'
      | 'buy_desk_klondaik_red_gold_6'
      | 'buy_clone_klondaik_red_gold_1'
      | 'buy_clone_klondaik_red_gold_2'
      | 'buy_clone_klondaik_red_gold_3'
      | 'buy_clone_klondaik_red_gold_4'
      | 'buy_clone_klondaik_red_gold_5'
      | 'buy_clone_klondaik_red_gold_6'
      | 'bonus_klondaik_red_gold_1'
      | 'bonus_klondaik_red_gold_2'
      | 'bonus_klondaik_red_gold_3'
      | 'bonus_klondaik_red_gold_4'
      | 'bonus_klondaik_red_gold_5'
      | 'bonus_klondaik_red_gold_6'
      | 'referral_bonus_klondaik_red_gold_1'
      | 'referral_bonus_klondaik_red_gold_2'
      | 'referral_bonus_klondaik_red_gold_3'
      | 'referral_bonus_klondaik_red_gold_4'
      | 'referral_bonus_klondaik_red_gold_5'
      | 'referral_bonus_klondaik_red_gold_6'
      | 'cumulative_bonus_klondaik_red_gold_1'
      | 'cumulative_bonus_klondaik_red_gold_2'
      | 'cumulative_bonus_klondaik_red_gold_3'
      | 'cumulative_bonus_klondaik_red_gold_4'
      | 'cumulative_bonus_klondaik_red_gold_5'
      | 'cumulative_bonus_klondaik_red_gold_6'
      | 'buy_desk_klondaik_yellow_gold_1'
      | 'buy_desk_klondaik_yellow_gold_2'
      | 'buy_desk_klondaik_yellow_gold_3'
      | 'buy_desk_klondaik_yellow_gold_4'
      | 'buy_desk_klondaik_yellow_gold_5'
      | 'buy_desk_klondaik_yellow_gold_6'
      | 'buy_clone_klondaik_yellow_gold_1'
      | 'buy_clone_klondaik_yellow_gold_2'
      | 'buy_clone_klondaik_yellow_gold_3'
      | 'buy_clone_klondaik_yellow_gold_4'
      | 'buy_clone_klondaik_yellow_gold_5'
      | 'buy_clone_klondaik_yellow_gold_6'
      | 'bonus_klondaik_yellow_gold_1'
      | 'bonus_klondaik_yellow_gold_2'
      | 'bonus_klondaik_yellow_gold_3'
      | 'bonus_klondaik_yellow_gold_4'
      | 'bonus_klondaik_yellow_gold_5'
      | 'bonus_klondaik_yellow_gold_6'
      | 'referral_bonus_klondaik_yellow_gold_1'
      | 'referral_bonus_klondaik_yellow_gold_2'
      | 'referral_bonus_klondaik_yellow_gold_3'
      | 'referral_bonus_klondaik_yellow_gold_4'
      | 'referral_bonus_klondaik_yellow_gold_5'
      | 'referral_bonus_klondaik_yellow_gold_6'
      | 'cumulative_bonus_klondaik_yellow_gold_1'
      | 'cumulative_bonus_klondaik_yellow_gold_2'
      | 'cumulative_bonus_klondaik_yellow_gold_3'
      | 'cumulative_bonus_klondaik_yellow_gold_4'
      | 'cumulative_bonus_klondaik_yellow_gold_5'
      | 'cumulative_bonus_klondaik_yellow_gold_6'
      | 'buy_desk_klondaik_white_gold_1'
      | 'buy_desk_klondaik_white_gold_2'
      | 'buy_desk_klondaik_white_gold_3'
      | 'buy_desk_klondaik_white_gold_4'
      | 'buy_desk_klondaik_white_gold_5'
      | 'buy_desk_klondaik_white_gold_6'
      | 'buy_clone_klondaik_white_gold_1'
      | 'buy_clone_klondaik_white_gold_2'
      | 'buy_clone_klondaik_white_gold_3'
      | 'buy_clone_klondaik_white_gold_4'
      | 'buy_clone_klondaik_white_gold_5'
      | 'buy_clone_klondaik_white_gold_6'
      | 'bonus_klondaik_white_gold_1'
      | 'bonus_klondaik_white_gold_2'
      | 'bonus_klondaik_white_gold_3'
      | 'bonus_klondaik_white_gold_4'
      | 'bonus_klondaik_white_gold_5'
      | 'bonus_klondaik_white_gold_6'
      | 'referral_bonus_klondaik_white_gold_1'
      | 'referral_bonus_klondaik_white_gold_2'
      | 'referral_bonus_klondaik_white_gold_3'
      | 'referral_bonus_klondaik_white_gold_4'
      | 'referral_bonus_klondaik_white_gold_5'
      | 'referral_bonus_klondaik_white_gold_6'
      | 'cumulative_bonus_klondaik_white_gold_1'
      | 'cumulative_bonus_klondaik_white_gold_2'
      | 'cumulative_bonus_klondaik_white_gold_3'
      | 'cumulative_bonus_klondaik_white_gold_4'
      | 'cumulative_bonus_klondaik_white_gold_5'
      | 'cumulative_bonus_klondaik_white_gold_6'
      | 'buy_pre_order_megabox_standard_1'
      | 'buy_pre_order_megabox_standard_2'
      | 'buy_pre_order_megabox_standard_3'
      | 'buy_pre_order_megabox_standard_4'
      | 'buy_pre_order_megabox_standard_5'
      | 'buy_pre_order_megabox_business_1'
      | 'buy_pre_order_megabox_business_2'
      | 'buy_pre_order_megabox_business_3'
      | 'buy_pre_order_megabox_business_4'
      | 'buy_pre_order_megabox_business_5'
      | 'buy_pre_order_megabox_premium_1'
      | 'buy_pre_order_megabox_premium_2'
      | 'buy_pre_order_megabox_premium_3'
      | 'buy_pre_order_megabox_premium_4'
      | 'buy_pre_order_megabox_premium_5'
      | 'buy_pre_order_megabox_vip_1'
      | 'buy_pre_order_megabox_vip_2'
      | 'buy_pre_order_megabox_vip_3'
      | 'buy_pre_order_megabox_vip_4'
      | 'buy_pre_order_megabox_vip_5'
      | 'buy_desk_megabox_standard_1'
      | 'buy_desk_megabox_standard_2'
      | 'buy_desk_megabox_standard_3'
      | 'buy_desk_megabox_standard_4'
      | 'buy_desk_megabox_standard_5'
      | 'buy_clone_megabox_standard_1'
      | 'buy_clone_megabox_standard_2'
      | 'buy_clone_megabox_standard_3'
      | 'buy_clone_megabox_standard_4'
      | 'buy_clone_megabox_standard_5'
      | 'bonus_megabox_standard_1'
      | 'bonus_megabox_standard_2'
      | 'bonus_megabox_standard_3'
      | 'bonus_megabox_standard_4'
      | 'bonus_megabox_standard_5'
      | 'cumulative_bonus_megabox_standard_1'
      | 'cumulative_bonus_megabox_standard_2'
      | 'cumulative_bonus_megabox_standard_3'
      | 'cumulative_bonus_megabox_standard_4'
      | 'cumulative_bonus_megabox_standard_5'
      | 'buy_desk_megabox_business_1'
      | 'buy_desk_megabox_business_2'
      | 'buy_desk_megabox_business_3'
      | 'buy_desk_megabox_business_4'
      | 'buy_desk_megabox_business_5'
      | 'buy_clone_megabox_business_1'
      | 'buy_clone_megabox_business_2'
      | 'buy_clone_megabox_business_3'
      | 'buy_clone_megabox_business_4'
      | 'buy_clone_megabox_business_5'
      | 'bonus_megabox_business_1'
      | 'bonus_megabox_business_2'
      | 'bonus_megabox_business_3'
      | 'bonus_megabox_business_4'
      | 'bonus_megabox_business_5'
      | 'cumulative_bonus_megabox_business_1'
      | 'cumulative_bonus_megabox_business_2'
      | 'cumulative_bonus_megabox_business_3'
      | 'cumulative_bonus_megabox_business_4'
      | 'cumulative_bonus_megabox_business_5'
      | 'buy_desk_megabox_premium_1'
      | 'buy_desk_megabox_premium_2'
      | 'buy_desk_megabox_premium_3'
      | 'buy_desk_megabox_premium_4'
      | 'buy_desk_megabox_premium_5'
      | 'buy_clone_megabox_premium_1'
      | 'buy_clone_megabox_premium_2'
      | 'buy_clone_megabox_premium_3'
      | 'buy_clone_megabox_premium_4'
      | 'buy_clone_megabox_premium_5'
      | 'bonus_megabox_premium_1'
      | 'bonus_megabox_premium_2'
      | 'bonus_megabox_premium_3'
      | 'bonus_megabox_premium_4'
      | 'bonus_megabox_premium_5'
      | 'cumulative_bonus_megabox_premium_1'
      | 'cumulative_bonus_megabox_premium_2'
      | 'cumulative_bonus_megabox_premium_3'
      | 'cumulative_bonus_megabox_premium_4'
      | 'cumulative_bonus_megabox_premium_5'
      | 'buy_desk_megabox_vip_1'
      | 'buy_desk_megabox_vip_2'
      | 'buy_desk_megabox_vip_3'
      | 'buy_desk_megabox_vip_4'
      | 'buy_desk_megabox_vip_5'
      | 'buy_clone_megabox_vip_1'
      | 'buy_clone_megabox_vip_2'
      | 'buy_clone_megabox_vip_3'
      | 'buy_clone_megabox_vip_4'
      | 'buy_clone_megabox_vip_5'
      | 'bonus_megabox_vip_1'
      | 'bonus_megabox_vip_2'
      | 'bonus_megabox_vip_3'
      | 'bonus_megabox_vip_4'
      | 'bonus_megabox_vip_5'
      | 'cumulative_bonus_megabox_vip_1'
      | 'cumulative_bonus_megabox_vip_2'
      | 'cumulative_bonus_megabox_vip_3'
      | 'cumulative_bonus_megabox_vip_4'
      | 'cumulative_bonus_megabox_vip_5'
      | 'buy_pre_order_speedbox_s1_1'
      | 'buy_pre_order_speedbox_s1_2'
      | 'buy_pre_order_speedbox_s1_3'
      | 'buy_pre_order_speedbox_s2_1'
      | 'buy_pre_order_speedbox_s2_2'
      | 'buy_pre_order_speedbox_s2_3'
      | 'buy_pre_order_speedbox_s3_1'
      | 'buy_pre_order_speedbox_s3_2'
      | 'buy_pre_order_speedbox_s3_3'
      | 'buy_pre_order_speedbox_s4_1'
      | 'buy_pre_order_speedbox_s4_2'
      | 'buy_pre_order_speedbox_s4_3'
      | 'buy_pre_order_speedbox_s5_1'
      | 'buy_pre_order_speedbox_s5_2'
      | 'buy_pre_order_speedbox_s5_3'
    >,
    direction?: 1 | -1,
    createdAtStart: string = '',
    createdAtEnd: string = '',
    sort?: 'created' | 'username',
    order?: 'ASC' | 'DESC'
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<DetailTransactionViewModel>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/accounting/transaction/list',
      query: {
        offset: offset,
        limit: limit,
        username: username,
        'status[]': status,
        'type[]': type,
        direction: direction,
        'createdAt[start]': createdAtStart,
        'createdAt[end]': createdAtEnd,
        sort: sort,
        order: order,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets detailed information about the transaction.
   * @param transactionId Transaction ID
   * @returns any Returns detailed information about the transaction.
   * @throws ApiError
   */
  public static getOneDetailTransaction(transactionId: string): CancelablePromise<{
    status: boolean;
    data: DetailTransactionViewModel;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/accounting/transaction/detail/{transactionId}',
      path: {
        transactionId: transactionId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates the transaction
   * @param transactionId Transaction ID
   * @param requestBody New transaction values
   * @returns void
   * @throws ApiError
   */
  public static updateTransaction(
    transactionId: string,
    requestBody: UpdateTransactionRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/accounting/transaction/update/{transactionId}',
      path: {
        transactionId: transactionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }
}
