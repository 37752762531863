import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { ApiError, LineSpeedBoxStatisticsItem, PreOrderDeskListItem, SpeedBoxMarketingService } from 'api/client';
import RootStore from 'store';
import {
  GroupedSpeedBoxMarketingPrestartDesksType,
  PurchaseSpeedBoxPlacesModalConfigType,
  SpeedBoxLockedDeskModalConfigType,
  SpeedBoxMarketingStatsFilterType,
} from 'types/speedBoxMarketing';

export default class SpeedBoxMarketingStore {
  rootStore: RootStore;

  purchasePlacesModalConfig: PurchaseSpeedBoxPlacesModalConfigType | null;

  lockedDeskModalConfig: SpeedBoxLockedDeskModalConfigType | null;

  prestartDesks: PreOrderDeskListItem[] | null;

  isStatsFilterModalOpen: boolean;

  stats: {
    items: LineSpeedBoxStatisticsItem[];
    total: number;
    perPage: number;
    page: number;
    filter: SpeedBoxMarketingStatsFilterType;
  };

  loading: {
    getPrestartDesks: boolean;
    getStats: boolean;
  };

  errors: {
    getPrestartDesks: ApiError | null;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'speedBoxMarketingStore' });
    this.rootStore = rootStore;
    this.purchasePlacesModalConfig = null;
    this.isStatsFilterModalOpen = false;
    this.lockedDeskModalConfig = null;

    this.prestartDesks = null;
    this.stats = {
      items: [],
      total: 0,
      perPage: 12,
      page: 0,
      filter: {
        line: 1,
        isBuy: true,
        username: '',
      },
    };

    this.loading = {
      getPrestartDesks: false,
      getStats: false,
    };
    this.errors = {
      getPrestartDesks: null,
    };
  }

  get prestartDesksGroupedByBlocks() {
    return this.prestartDesks
      ? (groupBy(this.prestartDesks, (desk) => desk.blockName) as GroupedSpeedBoxMarketingPrestartDesksType)
      : null;
  }

  *getPrestartDesks() {
    try {
      this.loading.getPrestartDesks = true;
      const { data } = yield SpeedBoxMarketingService.getSpeedBoxPreOrderDeskList();
      this.prestartDesks = data.items;
    } catch (error) {
      this.errors.getPrestartDesks = error as ApiError;
      console.log('[SpeedBoxMarketingStore] getPrestartDesks error:', error);
    } finally {
      this.loading.getPrestartDesks = false;
    }
  }

  *getStats() {
    try {
      this.loading.getStats = true;
      const { data } = yield SpeedBoxMarketingService.getSpeedBoxPreOrderStatistics(
        this.stats.perPage * this.stats.page,
        this.stats.perPage,
        this.stats.filter.line,
        this.stats.filter.username,
        this.stats.filter.isBuy
      );

      this.stats = {
        ...this.stats,
        ...data,
      };
    } catch (error) {
      console.log('[SpeedBoxMarketingStore] getStats error:', error);
    } finally {
      this.loading.getStats = false;
    }
  }

  updateStatsFilter(newFilter: SpeedBoxMarketingStatsFilterType) {
    this.stats.filter = newFilter;
    this.stats.page = 0;
    this.getStats();
  }

  updateStatsPage(newPage: number) {
    this.stats.page = newPage;
    this.getStats();
  }

  openPurchasePlacesModal(config: PurchaseSpeedBoxPlacesModalConfigType) {
    this.purchasePlacesModalConfig = config;
  }

  closePurchasePlacesModal() {
    this.purchasePlacesModalConfig = null;
  }

  openStatsFilterModal() {
    this.isStatsFilterModalOpen = true;
  }

  closeStatsFilterModal() {
    this.isStatsFilterModalOpen = false;
  }

  openLockedDeskModal(config: SpeedBoxLockedDeskModalConfigType) {
    this.lockedDeskModalConfig = config;
  }

  closeLockedDeskModal() {
    this.lockedDeskModalConfig = null;
  }
}
