/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RafflePrize {
  GREEN_BOX = 'GreenBox',
  BLUE_BOX = 'BlueBox',
  RED_BOX = 'RedBox',
  BRONZE_BOX_1 = 'BronzeBox 1',
  BRONZE_BOX_2 = 'BronzeBox 2',
  BRONZE_BOX_3 = 'BronzeBox 3',
  BRONZE_BOX_4 = 'BronzeBox 4',
  BRONZE_BOX_5 = 'BronzeBox 5',
  BRONZE_BOX_6 = 'BronzeBox 6',
  SILVER_BOX_1 = 'SilverBox 1',
  SILVER_BOX_2 = 'SilverBox 2',
  SILVER_BOX_3 = 'SilverBox 3',
  SILVER_BOX_4 = 'SilverBox 4',
  SILVER_BOX_5 = 'SilverBox 5',
  SILVER_BOX_6 = 'SilverBox 6',
  GOLD_BOX_1 = 'GoldBox 1',
  GOLD_BOX_2 = 'GoldBox 2',
  GOLD_BOX_3 = 'GoldBox 3',
  GOLD_BOX_4 = 'GoldBox 4',
  GOLD_BOX_5 = 'GoldBox 5',
  GOLD_BOX_6 = 'GoldBox 6',
  EMERALD_1 = 'Emerald 1',
  EMERALD_2 = 'Emerald 2',
  EMERALD_3 = 'Emerald 3',
  RUBY_1 = 'Ruby 1',
  RUBY_2 = 'Ruby 2',
  RUBY_3 = 'Ruby 3',
  DIAMOND_1 = 'Diamond 1',
  DIAMOND_2 = 'Diamond 2',
  DIAMOND_3 = 'Diamond 3',
  YELLOW1 = 'Yellow1',
  YELLOW2 = 'Yellow2',
  YELLOW3 = 'Yellow3',
  YELLOW4 = 'Yellow4',
  YELLOW5 = 'Yellow5',
  YELLOW6 = 'Yellow6',
  GREEN1 = 'Green1',
  GREEN2 = 'Green2',
  GREEN3 = 'Green3',
  GREEN4 = 'Green4',
  GREEN5 = 'Green5',
  GREEN6 = 'Green6',
  ORANGE1 = 'Orange1',
  ORANGE2 = 'Orange2',
  ORANGE3 = 'Orange3',
  ORANGE4 = 'Orange4',
  ORANGE5 = 'Orange5',
  ORANGE6 = 'Orange6',
  RED_GOLD1 = 'RedGold1',
  RED_GOLD2_RED_GOLD1 = 'RedGold2 + RedGold1',
  RED_GOLD3_RED_GOLD1 = 'RedGold3 + RedGold1',
  RED_GOLD4_RED_GOLD1 = 'RedGold4 + RedGold1',
  RED_GOLD5_RED_GOLD1 = 'RedGold5 + RedGold1',
  RED_GOLD6_RED_GOLD1 = 'RedGold6 + RedGold1',
  YELLOW_GOLD1_RED_GOLD1 = 'YellowGold1 + RedGold1',
  YELLOW_GOLD2_RED_GOLD1_YELLOW_GOLD1 = 'YellowGold2 + RedGold1 + YellowGold1',
  YELLOW_GOLD3_RED_GOLD1_YELLOW_GOLD1 = 'YellowGold3 + RedGold1 + YellowGold1',
  YELLOW_GOLD4_RED_GOLD1_YELLOW_GOLD1 = 'YellowGold4 + RedGold1 + YellowGold1',
  YELLOW_GOLD5_RED_GOLD1_YELLOW_GOLD1 = 'YellowGold5 + RedGold1 + YellowGold1',
  YELLOW_GOLD6_RED_GOLD1_YELLOW_GOLD1 = 'YellowGold6 + RedGold1 + YellowGold1',
  WHITE_GOLD1_RED_GOLD1_YELLOW_GOLD1 = 'WhiteGold1 + RedGold1 + YellowGold1',
  WHITE_GOLD2_RED_GOLD1_YELLOW_GOLD1_WHITE_GOLD1 = 'WhiteGold2 + RedGold1 + YellowGold1 + WhiteGold1',
  WHITE_GOLD3_RED_GOLD1_YELLOW_GOLD1_WHITE_GOLD1 = 'WhiteGold3 + RedGold1 + YellowGold1 + WhiteGold1',
  WHITE_GOLD4_RED_GOLD1_YELLOW_GOLD1_WHITE_GOLD1 = 'WhiteGold4 + RedGold1 + YellowGold1 + WhiteGold1',
  WHITE_GOLD5_RED_GOLD1_YELLOW_GOLD1_WHITE_GOLD1 = 'WhiteGold5 + RedGold1 + YellowGold1 + WhiteGold1',
  WHITE_GOLD6_RED_GOLD1_YELLOW_GOLD1_WHITE_GOLD1 = 'WhiteGold6 + RedGold1 + YellowGold1 + WhiteGold1',
  STANDARD1_STANDARD5_BONUS = 'Standard1 + Standard5 Bonus',
  STANDARD2 = 'Standard2',
  STANDARD3 = 'Standard3',
  STANDARD4 = 'Standard4',
  STANDARD5_BONUS = 'Standard5 Bonus',
  STANDARD5_BONUS_ = 'Standard5 Bonus Тех',
  BUSINESS1_BUSINESS5_BONUS = 'Business1 + Business5 Bonus',
  BUSINESS2 = 'Business2',
  BUSINESS3 = 'Business3',
  BUSINESS4 = 'Business4',
  BUSINESS5_BONUS = 'Business5 Bonus',
  BUSINESS5_BONUS_ = 'Business5 Bonus Тех',
  PREMIUM1_PREMIUM5_BONUS = 'Premium1 + Premium5 Bonus',
  PREMIUM2 = 'Premium2',
  PREMIUM3 = 'Premium3',
  PREMIUM4 = 'Premium4',
  PREMIUM5_BONUS = 'Premium5 Bonus',
  PREMIUM5_BONUS_ = 'Premium5 Bonus Тех',
  VIP1_VIP5_BONUS = 'Vip1 + Vip5 Bonus',
  VIP2 = 'Vip2',
  VIP3 = 'Vip3',
  VIP4 = 'Vip4',
  VIP5_BONUS = 'Vip5 Bonus',
  VIP5_BONUS_ = 'Vip5 Bonus Тех',
}
