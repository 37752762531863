import React, { useEffect } from 'react';

import cx from 'classnames';
import numbro from 'numbro';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { useStore } from 'store';
import routes from 'config/routes';
import { useScrollLock, useWindowSize } from 'utils/hooks';

import Button from 'components/inputs/Button';
import Logo from 'components/dataDisplay/Logo';
import Avatar from 'components/dataDisplay/Avatar';
import Typography from 'components/dataDisplay/Typography';
import LanguageSelect from 'components/inputs/LanguageSelect';

import { ReactComponent as CloseIcon } from 'static/icons/close.svg';
import { ReactComponent as SignoutIcon } from 'static/icons/signout.svg';
// import { ReactComponent as SnowflakeIcon } from 'static/icons/snowflake-silhouette.svg';

import styles from './Sidebar.module.scss';

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  // const winterThemeIndex = useRef(random(1, 3));
  const location = useLocation();
  const { mobileLayout } = useWindowSize();
  const { authStore, layoutStore, userStore, accountingStore } = useStore();

  useScrollLock(layoutStore.isSidebarOpen && !!mobileLayout);

  useEffect(() => {
    if (mobileLayout !== null && !mobileLayout && !layoutStore.isSidebarOpen) {
      layoutStore.openSidebar();
    } else if (mobileLayout && layoutStore.isSidebarOpen) {
      layoutStore.closeSidebar();
    }
  }, [layoutStore, mobileLayout]);

  useEffect(() => {
    layoutStore.isSidebarOpen && mobileLayout && layoutStore.closeSidebar();
  }, [layoutStore, location, mobileLayout]);

  return (
    <AnimatePresence>
      {layoutStore.isSidebarOpen && (
        <motion.div
          key="sidebar"
          className={cx(styles.Sidebar /* styles[`winter-${winterThemeIndex.current}`] */)}
          variants={{
            hide: { opacity: 0, marginRight: '-20px' },
            show: { opacity: 1, marginRight: '0px' },
          }}
          initial="hide"
          animate="show"
          exit="hide"
        >
          <div className={styles.content}>
            <Button.RouterLink className={styles.logoLink} variant="wrapper" to={routes.main}>
              <Logo className={styles.logo} variant="verticalCentered" color="white" />
            </Button.RouterLink>

            <div className={styles.heading}>
              <div className={styles.userInfo}>
                <Avatar
                  className={styles.avatar}
                  avatar={userStore.user?.avatar}
                  name={userStore.userFullName}
                  shape="square"
                />

                <div className={styles.text}>
                  <Typography className={styles.username} element="p" variant="paragraph1" color="white">
                    {userStore.user?.username}
                  </Typography>

                  <Typography className={styles.name} element="p" variant="caption1" color="white">
                    {userStore.userFullName}
                  </Typography>
                </div>
              </div>

              <span className={styles.balance}>
                {numbro(accountingStore.totalBalance).format({ trimMantissa: false })}
              </span>

              <Button className={styles.close} variant="wrapper" onClick={layoutStore.closeSidebar}>
                <CloseIcon />
              </Button>
            </div>

            <LanguageSelect className={styles.languageSelect} />

            <nav className={styles.nav}>
              {!!userStore.user?.roles.length && (
                <NavLink className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })} to={routes.admin}>
                  {userStore.user?.isAdmin && t('sidebar.links.adminPanel')}
                  {userStore.user?.isSupport && 'Support'}
                  {userStore.user?.isSmm && 'SMM'}
                </NavLink>
              )}

              <NavLink className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })} to={routes.main}>
                {t('sidebar.links.mainPage')}
              </NavLink>
              <NavLink className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })} to={routes.finance}>
                {t('sidebar.links.accounting')}
              </NavLink>
              <NavLink
                className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })}
                to={routes.marketing}
              >
                {t('sidebar.links.marketings')}
              </NavLink>
              <NavLink
                className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })}
                to={routes.structure}
              >
                {t('sidebar.links.structure')}
              </NavLink>
              <NavLink
                className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })}
                to={routes.magicBox}
              >
                Magic Box
              </NavLink>
            </nav>

            {/* <div className={styles.nav}>
              <label className={cx(styles.snowSwitcher, { [styles.enabled]: layoutStore.isSnowEnabled })}>
                <input
                  className={styles.input}
                  type="checkbox"
                  checked={layoutStore.isSnowEnabled}
                  onChange={layoutStore.toggleSnow}
                />

                <div className={styles.switcher}>
                  <span className={styles.dot}>
                    <SnowflakeIcon className={styles.icon} />
                  </span>
                </div>

                <Typography className={styles.label} variant="caption1" color="white">
                  {layoutStore.isSnowEnabled ? 'Снег идет' : 'Включить снег'}
                </Typography>
              </label>
            </div> */}

            <nav className={styles.nav}>
              <NavLink
                className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })}
                to={routes.settings}
              >
                {t('sidebar.links.settings')}
              </NavLink>
              <NavLink
                className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })}
                to={routes.notifications}
              >
                {t('sidebar.links.notifications')}
              </NavLink>
              <NavLink
                className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })}
                to={routes.documents}
              >
                {t('sidebar.links.presentations')}
              </NavLink>
              <NavLink className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })} to={routes.reviews}>
                {t('sidebar.links.reviews')}
              </NavLink>
            </nav>

            <Button className={styles.logout} variant="text" onClick={authStore.logout} iconStart={SignoutIcon}>
              {t('sidebar.logout')}
            </Button>
          </div>
        </motion.div>
      )}

      {layoutStore.isSidebarOpen && (
        <motion.div
          key="sidebarOverlay"
          variants={{ hide: { opacity: 0 }, show: { opacity: 1 } }}
          initial="hide"
          animate="show"
          exit="hide"
          className={styles.Overlay}
          onClick={() => layoutStore.closeSidebar()}
        />
      )}
    </AnimatePresence>
  );
};

export default observer(Sidebar);
