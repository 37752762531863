import React from 'react';

import AuthStore from './authStore';
import LayoutStore from './layoutStore';
import UserStore from './userStore';
import AccountingStore from './accountingStore';
import MainMarketingStore from './mainMarketingStore';
import MagicBoxStore from './magicBoxStore';
import AdminStore from './adminStore';
import BoomboxMarketingStore from './boomboxMarketingStore';
import TopboxMarketingStore from './topboxMarketingStore';
import TictacMarketingStore from './tictacMarketingStore';
import KlondikeMarketingStore from './klondikeMarketingStore';
import MegaBoxMarketingStore from './megaBoxMarketingStore';
import SpeedBoxMarketingStore from './speedBoxMarketingStore';

interface IStoreProviderProps {
  children: React.ReactNode;
  store: RootStore;
}

export default class RootStore {
  authStore: AuthStore;

  layoutStore: LayoutStore;

  userStore: UserStore;

  accountingStore: AccountingStore;

  mainMarketingStore: MainMarketingStore;

  boomboxMarketingStore: BoomboxMarketingStore;

  topboxMarketingStore: TopboxMarketingStore;

  tictacMarketingStore: TictacMarketingStore;

  klondikeMarketingStore: KlondikeMarketingStore;

  megaBoxMarketingStore: MegaBoxMarketingStore;

  speedBoxMarketingStore: SpeedBoxMarketingStore;

  magicBoxStore: MagicBoxStore;

  adminStore: AdminStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.authStore = new AuthStore(this);
    this.layoutStore = new LayoutStore(this);
    this.accountingStore = new AccountingStore(this);
    this.mainMarketingStore = new MainMarketingStore(this);
    this.boomboxMarketingStore = new BoomboxMarketingStore(this);
    this.topboxMarketingStore = new TopboxMarketingStore(this);
    this.adminStore = new AdminStore(this);
    this.magicBoxStore = new MagicBoxStore(this);
    this.tictacMarketingStore = new TictacMarketingStore(this);
    this.klondikeMarketingStore = new KlondikeMarketingStore(this);
    this.megaBoxMarketingStore = new MegaBoxMarketingStore(this);
    this.speedBoxMarketingStore = new SpeedBoxMarketingStore(this);
  }
}

export const StoreContext = React.createContext<RootStore>({} as RootStore);

export function StoreProvider({ store, children }: IStoreProviderProps) {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

export const useStore = () => React.useContext(StoreContext);
